<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet>
          <template v-slot:title>
            Уведомления
          </template>
          <template v-slot:toolbar>            
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>Дата</th>
                <th>Тип</th>
                <th>Уведомление</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in notifications" :key="k">
                <td>
                  {{ UtilsService.formatDate(item.date)}}
                </td>
                <td>
                  {{ showType(item.type)}}
                </td>      
                <td v-html="item.notification">
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import UtilsService from "@/common/utils.service";

const TYPE_JOURNAL_NOTE = "journalNote";
export default {
  name: "Notifications",
  components: {
    KTPortlet
  },
  data() {
    return {
      notifications:[]
    };
  },
  setup() {
    return { UtilsService }
  },
  mounted() {
   this.loadNotifications();
  },
  methods: {
    
    loadNotifications() {
      let $this = this;
      ApiService.querySecured("notifications/getFoUser")
          .then(({data}) =>  {
            $this.notifications = data;
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    showType(type){
      if(type === TYPE_JOURNAL_NOTE){
        return "Замечанеи по ведению журнала";
      }
      
      return "";
    }
  }
};
</script>
